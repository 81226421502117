export { default as IntroOneIcon } from './intro/2d_intro_1_img.svg';
export { default as IntroTwoIcon } from './intro/2d_intro_2_img.svg';
export { default as IntroThreeIcon } from './intro/2d_intro_3_img.svg';

export { default as EmptyResultIcon } from './2d_full_img.svg';
export { default as BirdHeadIcon } from './bird_head.svg';
export { default as DialingIcon } from './dialing-icon.svg';
export { default as LogoIcon } from './logo-icon.svg';
export { default as MapIcon } from './map-icon.svg';
export { default as ReceiptIcon } from './receipt_icon.svg';
export { default as SpinnerIcon } from './tool_com_spin_img.svg';
